<template>
  <div class="productSkeleton">
    <div class="productSkeleton__product" v-for="index in count" :key="index">
      <div class="skeleton-box imgbox"></div>
      <div class="skeleton-box lbox"></div>
      <div class="skeleton-box sbox"></div>
      <div class="skeleton-box sbox"></div>
      <div class="skeleton-box btnbox"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    count: { type: Number, default: "8" },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #eaeaea;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
.imgbox {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 10px;
}
.lbox {
  width: 70%;
  height: 40px;
  margin-top: 20px;
  border-radius: 10px;
}
.sbox {
  &:not(:last-of-type) {
    margin-top: 20px;
    width: 80%;
  }
  width: 85%;
  height: 15px;
  margin-top: 7px;
  border-radius: 10px;
}
.btnbox {
  width: 60%;
  height: 40px;
  border-radius: 10px;
  margin-top: auto;
  align-self: center;
  margin-top: 20px;
}
.productSkeleton {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
  &__product {
    padding: 15px;
    width: 12%;
    display: flex;
    flex-direction: column;
    height: 576px;
    background-color: white;
    margin-bottom: 20px;
    box-shadow: 0px 0.5px 4px 2px rgba(67, 81, 96, 0.05);
    border-radius: 15px;
    @media (max-width: 2200px) {
      width: 30%;
    }
    @media (max-width: 1300px) {
      width: 30%;
    }
    @media (max-width: 990px) {
      width: 45%;
    }
    @media (max-width: 615px) {
      width: 100%;
    }
  }
}
</style>
