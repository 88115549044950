<template>
  <button class="vgang_btn" @click.stop="$emit('clicked')">
    <span v-if="icon" class="material-icons icon">
      {{ icon }}
    </span>
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
  },
};
</script>

<style lang="less" scoped>
</style>