<template>
  <div class="retailer-home-list">
    <div class="advance_search">
      <SearchBtnV2
        class="Polaris-Filters-ConnectedFilterControl__CenterContainer"
        placeholder="Search a product "
        :searchText="routeSearchQuery"
        @search="Search($event)"
      />
      <div class="base-select-container d-flex">
        <BaseSelectDrop
          pointer="title"
          :placeholder="'Categories'"
          :options="[...filters.categories]"
          :selectedOption="selectedCategory"
          @selectOption="SortByObject($event, 'category')"
        />

        <BaseSelectDrop
          :placeholder="'Attributes'"
          :options="[...filters.tags]"
          :selectedOption="selectedTag"
          @selectOption="SortByObject($event, 'tag')"
        />

        <BaseSelectDrop
          pointer="name"
          :placeholder="'Ship from'"
          :options="[...filters.countries]"
          :selectedOption="selectedCountry"
          @selectOption="SortByObject($event, 'country')"
        />

        <BaseSelectDrop
          pointer="name"
          :placeholder="'Shipping time'"
          :options="[...filters.shipping_times]"
          :selectedOption="selectedShippingTime"
          @selectOption="SortByObject($event, 'shipping_time')"
        />

        <BaseSelectDropSort
          class="sort-container"
          :placeholder="'Sort '"
          :options="sortList"
          :selectedOption="selectedSort"
          @selectOption="SortByObject($event, 'sort')"
        />
      </div>
      <div class="base-select-container-mobile d-flex">
        <PButton @click="showModal = true" class="mr-2 more-filter-btn">
          More filters
        </PButton>
        <BaseSelectDropSort
          class="sort-container"
          :placeholder="'Sort '"
          :options="sortList"
          :selectedOption="selectedSort"
          @selectOption="SortByObject($event, 'sort')"
        />
      </div>
    </div>
    <div class="chipsContainer">
      <div
        v-if="chips.length > 0"
        class="chipsContainer__clearall"
        @click="removeAllChips"
      >
        Clear all
      </div>
      <SmartChips :chips="chips" @removeChips="removeChips" />
      <label
        class="Polaris-Choice Polaris-Checkbox__ChoiceLabel chipsContainer__outOfStock"
        for=":R1n6:"
      >
        <span class="Polaris-Choice__Control">
          <span class="Polaris-Checkbox">
            <input
              id=":R1n6:"
              type="checkbox"
              class="Polaris-Checkbox__Input"
              aria-invalid="false"
              role="checkbox"
              aria-checked="false"
              v-model="showOutofStock"
              @change="SortByObject($event, 'dont_show_out_of_stock')"
            />
            <span class="Polaris-Checkbox__Backdrop"> </span>
            <span class="Polaris-Checkbox__Icon">
              <span class="Polaris-Icon">
                <span class="Polaris-Text--root Polaris-Text--visuallyHidden">
                </span>
                <svg
                  viewBox="0 0 20 20"
                  class="Polaris-Icon__Svg"
                  focusable="false"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M14.03 7.22a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-2.25-2.25a.75.75 0 1 1 1.06-1.06l1.72 1.72 3.97-3.97a.75.75 0 0 1 1.06 0Z"
                  ></path>
                </svg>
              </span>
            </span>
          </span>
        </span>
        <span class="Polaris-Choice__Label">
          <span>Out of stock</span>
        </span>
      </label>
    </div>
    <div
      class="container-fluid pa-0"
      v-if="!hasNoProduct && this.products.length"
    >
      <div
        class="products-list-wrapper"
        ref="listContainer"
        v-if="showProducts"
      >
        <div class="productCard" v-for="product in products" :key="product.id">
          <ProductCard :product="product" :freeShipping="freeShipping" />
        </div>
      </div>
    </div>
    <v-container
      v-else-if="hasNoProduct && !this.products.length && !isLoadingNewProducts"
    >
      <span class="no-result">No products found!</span>
    </v-container>
    <div v-else-if="noConnectedShop">
      <div class="empty-space">
        <h2 class="_title">
          You have no connected shop, please connect to a shop first
        </h2>
        <img
          loading="lazy"
          src="@/assets/img/vgang-pale-bg.png"
          alt="vgang"
          class="_bg"
        />
      </div>
    </div>

    <SmartProductSkeleton v-if="isLoadingNewProducts" :count="8" />

    <div
      v-if="ProductsCount >= productCardCount"
      class="pagination-container mb-6 d-flex justify-space-between"
    >
      <nav aria-label="Pagination" class="Polaris-Pagination">
        <div
          class="Polaris-ButtonGroup Polaris-ButtonGroup--segmented"
          data-buttongroup-segmented="true"
        >
          <div
            @click="loadLessProducts"
            class="Polaris-ButtonGroup__Item"
            :style="{
              'pointer-events':
                products.length > productCardCount ? 'auto' : 'none',
              background:
                products.length > productCardCount ? 'none' : ' #f6f6f7',
            }"
          >
            <button
              id="previousURL"
              class="Polaris-Button Polaris-Button--outline Polaris-Button--iconOnly"
              aria-label="Previous"
              type="button"
            >
              <span class="Polaris-Button__Content">
                <span class="Polaris-Button__Icon">
                  <span class="Polaris-Icon">
                    <span
                      class="Polaris-Text--root Polaris-Text--visuallyHidden"
                    >
                    </span>
                    <svg
                      viewBox="0 0 20 20"
                      class="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M11.78 5.47a.75.75 0 0 1 0 1.06l-3.47 3.47 3.47 3.47a.75.75 0 1 1-1.06 1.06l-4-4a.75.75 0 0 1 0-1.06l4-4a.75.75 0 0 1 1.06 0Z"
                      ></path>
                    </svg>
                  </span>
                </span>
              </span>
            </button>
          </div>

          <div @click="loadMoreProducts" class="Polaris-ButtonGroup__Item">
            <button
              id="nextURL"
              class="Polaris-Button Polaris-Button--outline Polaris-Button--iconOnly"
              aria-label="Next"
              type="button"
            >
              <span class="Polaris-Button__Content">
                <span class="Polaris-Button__Icon">
                  <span class="Polaris-Icon">
                    <span
                      class="Polaris-Text--root Polaris-Text--visuallyHidden"
                    >
                    </span>
                    <svg
                      viewBox="0 0 20 20"
                      class="Polaris-Icon__Svg"
                      focusable="false"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.72 14.53a.75.75 0 0 1 0-1.06l3.47-3.47-3.47-3.47a.75.75 0 0 1 1.06-1.06l4 4a.75.75 0 0 1 0 1.06l-4 4a.75.75 0 0 1-1.06 0Z"
                      ></path>
                    </svg>
                  </span>
                </span>
              </span>
            </button>
          </div>
        </div>
      </nav>
      <div class="productList__header">
        <p v-if="ProductsCount" class="productList__header__listCount">
          <span>{{ products.length }} Products from {{ ProductsCount }}</span>
        </p>
      </div>
    </div>

    <div
      :class="{ 'visibility-hidden': !showModal }"
      class="retailer-home-list-modal"
      @click="showModal = false"
    >
      <div
        :class="{ 'retailer-home-list-modal-tx': showModal }"
        class="modal-content"
        @click.stop
      >
        <!-- Your form content goes here -->
        <div class="d-flex flex-column gap-3">
          <BaseSelectDrop
            pointer="title"
            :placeholder="'Categories'"
            :options="[...filters.categories]"
            :selectedOption="selectedCategory"
            @selectOption="SortByObject($event, 'category')"
          />

          <BaseSelectDrop
            :placeholder="'Attributes'"
            :options="[...filters.tags]"
            :selectedOption="selectedTag"
            @selectOption="SortByObject($event, 'tag')"
          />

          <BaseSelectDrop
            pointer="name"
            :placeholder="'Ship from'"
            :options="[...filters.countries]"
            :selectedOption="selectedCountry"
            @selectOption="SortByObject($event, 'country')"
          />

          <BaseSelectDrop
            pointer="name"
            :placeholder="'Shipping time'"
            :options="[...filters.shipping_times]"
            :selectedOption="selectedShippingTime"
            @selectOption="SortByObject($event, 'shipping_time')"
          />

          <BaseSelectDrop
            class="sort-container sort-container-mobile"
            :placeholder="'Sort'"
            :options="sortList"
            :selectedOption="selectedSort"
            @selectOption="SortByObject($event, 'sort')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// Components

import ProductCard from "@/components/ProductCard";

import SmartChips from "@/components/retailer/chips/SmartChips.vue";
import BaseSelectDrop from "@/components/bases/BaseSelectDrop.vue";
import BaseSelectDropSort from "@/components/bases/BaseSelectDropSort.vue";
import SmartProductSkeleton from "@/components/retailer/skeleton/SmartProductSkeleton.vue";
// Services
import RetailerProducts from "@/services/RetailerProducts";
import SearchBtnV2 from "@/components/bases/SearchBtnV2.vue";

export default {
  name: "ListOfProducts",
  components: {
    ProductCard,
    BaseSelectDropSort,
    SearchBtnV2,
    SmartChips,
    BaseSelectDrop,
    SmartProductSkeleton,
  },
  data() {
    return {
      products: [],
      categories: [],
      filters: [],
      popular_categories: [],
      sellerID: [],
      productCardCount: 24,
      page: Number,
      page_size: 0,
      totalProductCount: "",
      SearchButtonIsDisabled: true,
      currentSort: "Trending products",
      category: null,
      country: null,
      tag: null,
      shipping_time: null,
      hasNoProduct: false,
      noConnectedShop: false,
      userType: "retailer",
      showSortBy: false,
      freeShipping: false,
      sortBy: "Trending products",
      sort_by_arrow: false,
      showBanner: true,
      close: true,
      inStock: true,
      chips: [],
      selectedShipFrom: undefined,
      selectedTag: undefined,
      selectedCategory: undefined,
      selectedCountry: undefined,
      selecteddont_show_out_of_stock: undefined,
      selectedShippingTime: undefined,
      selectedSort: undefined,
      offset: 0,
      lastOffset: 0,
      isLoadingNewProducts: true,
      showProducts: true,
      showModal: false,
      sortList: [
        { id: "Trending products", value: "Trending products" },
        { id: "Latest", value: "Latest" },
        { id: "Price low to high", value: "Price low to high" },
        { id: "Price high to low", value: "Price high to low" },
        { id: "Winning products", value: "Winning products" },
      ],
      showOutofStock: false,
    };
  },

  created() {
    this.getCategories();
  },

  mounted() {
    const retailer = JSON.parse(JSON.stringify(this.$store.state.retailer));
    if (
      retailer.listOfProductsPagination &&
      Object.keys(retailer.listOfProductsPagination).length === 0 &&
      Object.getPrototypeOf(retailer.listOfProductsPagination) ===
        Object.prototype
    ) {
      this.page = 1;
    } else {
      this.page =
        retailer.listOfProductsPagination.offset / this.productCardCount + 1;
    }

    if (
      this.$route.query.dont_show_out_of_stock &&
      this.$route.query.dont_show_out_of_stock === 1
    ) {
      this.showOutofStock = false;
    } else if (
      this.$route.query.dont_show_out_of_stock &&
      this.$route.query.dont_show_out_of_stock === 0
    ) {
      this.showOutofStock = true;
    }

    this.getFilters();
    this.generateChips();
    this.generateFilters();
  },

  computed: {
    ...mapGetters({
      newAlert: "alert/newAlert",
      alertError: "alert/alertError",
      listOfProductsPagination: "retailer/listOfProductsPagination",
      listOfSellers: "retailer/listOfSellers",
      showMainBanner: "retailer/showMainBanner",
      ProductsCount: "retailer/ProductsCount",
    }),

    urlRoute() {
      return {
        count: this.productCardCount,
        offset: this.offset,
        ...this.$route.query,
      };
    },
    tagsList() {
      return this.filters.tags;
    },
    categoryList() {
      return this.filters.categories;
    },
    countryList() {
      return this.filters.countries;
    },
    shippingTimeList() {
      return this.filters.shipping_times;
    },
    routeSearchQuery() {
      return this.$route.query.search;
    },
  },
  watch: {
    urlRoute: {
      handler(oldVal, newVal) {
        if (
          !this.$route.query.hmac &&
          !this.$route.query.host &&
          !this.$route.query.shop
        ) {
          this.generateChips();
          this.getProducts(this.listOfProductsPagination);
        }
      },
      immediate: true,
    },

    tagsList() {
      this.generateFilters();
    },
    categoryList() {
      this.generateFilters();
    },
    countryList() {
      this.generateFilters();
    },
    shippingTimeList() {
      this.generateFilters();
    },
  },
  methods: {
    async Search(event) {
      const url_string = window.location.href;
      const url = new URL(url_string);
      let name = !url.pathname.includes("/sh-retailer")
        ? "Product list"
        : "ShopifyRetailerFindProducts";

      if (event) {
        this.$router.push({
          name,
          query: {
            category: this.$route.query.category,
            search: event,
            country: this.$route.query.country,
            sort: this.$route.query.sort,
            tag: this.$route.query.tag,
            shipping_time: this.$route.query.shipping_time,
            dont_show_out_of_stock: this.$route.query.dont_show_out_of_stock,
          },
        });
      } else {
        this.$router.push({
          name,
        });
      }
    },
    ...mapActions([
      "alert/clear",
      "alert/newAlert",
      "retailer/setListOfProductsPagination",
      "retailer/getListOfSellers",
      "retailer/updateShowMainBanner",
      "retailer/updateProductsCount",
    ]),
    handleScroll() {
      let listContainer = this.$refs["listContainer"];
      if (
        listContainer.getBoundingClientRect().bottom - window.innerHeight <=
          0 &&
        (this.offset / 24) % 3 !== 2
      ) {
        if (!this.isLoadingNewProducts) {
          this.isLoadingNewProducts = true;
          this.lastOffset = this.offset;
          this.offset += 24;
        }
      }
    },
    loadMoreProducts() {
      this.isLoadingNewProducts = true;
      this.lastOffset = this.offset;
      this.offset += this.productCardCount;
    },
    loadLessProducts() {
      this.isLoadingNewProducts = true;
      this.products.splice(this.productCardCount);
      this.offset -= this.productCardCount;
    },
    async removeAllChips() {
      let pag = this.listOfProductsPagination;

      this.selectedCategory = undefined;
      pag.category = undefined;

      this.selectedCountry = undefined;
      pag.country = undefined;

      this.selectedShippingTime = undefined;
      pag.shipping_time = undefined;

      this.selectedTag = undefined;
      pag.tag = undefined;

      this.selectedSort = undefined;
      pag.sort = undefined;

      this.lastOffset = this.offset = 0;
      this.page = 1;
      this.showProducts = false;
      this.isLoadingNewProducts = true;
      await this["retailer/setListOfProductsPagination"](pag);
      this.$router.replace({ query: null });
    },
    removeChips(chip) {
      let pag = this.listOfProductsPagination;
      let urlQueries = Object.assign({}, this.$route.query);

      if (chip.type === "search") {
        delete urlQueries[chip.type];
      } else if (chip.type === "country") {
        delete urlQueries[chip.type];
        pag.country = undefined;
        this.selectedCountry = undefined;
      } else if (chip.type === "tag") {
        delete urlQueries[chip.type];
        pag.tag = undefined;
        this.selectedTag = undefined;
      } else if (chip.type === "category") {
        delete urlQueries[chip.type];
        pag.category = undefined;
        this.selectedCategory = undefined;
      } else if (chip.type === "shipping_time") {
        delete urlQueries[chip.type];
        pag.shipping_time = undefined;
        this.selectedShippingTime = undefined;
      } else if (chip.type === "sort") {
        delete urlQueries[chip.type];
        pag.sort = undefined;
        this.selectedSort = undefined;
      }
      this.lastOffset = this.offset = 0;
      this.page = 1;
      this.showProducts = false;
      this.isLoadingNewProducts = true;
      this.$router.replace({
        query: urlQueries,
      });
    },
    generateFilters() {
      let pag = this.listOfProductsPagination;

      if (this.$route.query.tag) {
        this.selectedTag = this.filters.tags.find(
          (tag) => tag.id == this.$route.query.tag
        );
        pag.tag = this.$route.query.tag;
      }
      if (this.$route.query.country) {
        this.selectedCountry = this.filters.countries.find(
          (country) => country.id == this.$route.query.country
        );
        pag.country = this.$route.query.country;
      }
      if (this.$route.query.category) {
        this.selectedCategory = this.filters.categories.find(
          (category) => category.id == this.$route.query.category
        );
        pag.category = this.$route.query.category;
      }
    },
    async generateChips() {
      let newchips = [];
      if (this.$route.query.search) {
        newchips.push({
          type: "search",
          value: this.$route.query.search,
        });
      }
      if (this.$route.query.category) {
        newchips.push({
          type: "category",
          value: this.filters.categories.find(
            (category) => category.id == this.$route.query.category
          )?.title,
        });
      }
      if (this.$route.query.tag && this.$route.query.tag !== "all") {
        newchips.push({
          type: "tag",
          value: this.filters.tags.find(
            (tag) => tag.id == this.$route.query.tag
          )?.value,
        });
      }
      if (this.$route.query.shipping_time) {
        newchips.push({
          type: "shipping_time",
          value: this.filters.shipping_times.find(
            (shiTime) => shiTime.id == this.$route.query.shipping_time
          )?.name,
        });
      }
      if (this.$route.query.sort) {
        newchips.push({
          type: "sort",
          value: this.sortList.find(
            (sortItem) => sortItem.id == this.$route.query.sort
          )?.value,
        });
      }
      if (this.$route.query.country) {
        newchips.push({
          type: "country",
          value: this.filters.countries.find(
            (country) => country.id == this.$route.query.country
          )?.name,
        });
      }
      this.chips = newchips;
    },
    async generatePaginationFirstTime() {
      let pag = {
        count: this.productCardCount,
        offset: this.productCardCount * (this.page - 1),
        sort:
          this.$route.query.sort ||
          this.listOfProductsPagination.sort ||
          "Trending products",
        category:
          this.$route.query.category ||
          this.listOfProductsPagination.category ||
          "all",
        tag:
          this.$route.query.tag || this.listOfProductsPagination.tag || "all",
        country:
          this.$route.query.country ||
          this.listOfProductsPagination.country ||
          "all",
        shipping_time: "all",
        search:
          this.$route.query.search ||
          this.listOfProductsPagination.search ||
          undefined,
        countries:
          this.$route.query.countries ||
          this.listOfProductsPagination.countries ||
          undefined,
        subCategories:
          this.$route.query.subCategories ||
          this.listOfProductsPagination.subCategories ||
          undefined,
        categories:
          this.$route.query.categories ||
          this.listOfProductsPagination.categories ||
          undefined,
        dont_show_out_of_stock:
          this.$route.query.dont_show_out_of_stock ||
          this.listOfProductsPagination.dont_show_out_of_stock ||
          0,
      };
      await this["retailer/setListOfProductsPagination"](pag);
    },
    generateRoute() {
      let query = {
        category: this.listOfProductsPagination.category,
        search: this.$route.query.search,
        countries: this.listOfProductsPagination.countries,
        subCategories: this.listOfProductsPagination.subCategories,
        categories: this.listOfProductsPagination.categories,
        country: this.listOfProductsPagination.country,
        sort: this.listOfProductsPagination.sort,
        shipping_time: this.listOfProductsPagination.shipping_time,
        tag: this.listOfProductsPagination.tag,
      };
      if (this.showOutofStock) {
        query.dont_show_out_of_stock = 1;
      }
      this.$router
        .push({
          query,
        })
        .catch(() => {});
    },
    async getProducts(oldPag) {
      const filterByCategory =
        this.currentSort === ("newest" || "latest") ? null : this.currentSort;
      let pag = this.listOfProductsPagination;
      if (
        oldPag &&
        Object.keys(oldPag).length === 0 &&
        Object.getPrototypeOf(oldPag) === Object.prototype
      ) {
        await this["retailer/setListOfProductsPagination"](pag);
      } else await this["retailer/setListOfProductsPagination"](oldPag);

      RetailerProducts.getProducts(this.urlRoute)
        .then((response) => {
          if (response.data) {
            if (this.lastOffset === this.offset) {
              this.products = [...response.data.products];
            } else {
              this.products.push(...response.data.products);
            }
            this.freeShipping = response.data.freeShipping;
            this.totalProductCount = response.data.totalCount;
            this.setProductsCount(this.totalProductCount);
            this.page_size = Math.ceil(
              response.data.totalCount / this.productCardCount
            );
            this.hasNoProduct = this.products.length === 0;
            // window.scrollTo(0, 0);
          } else {
            this.hasNoProduct = true;
          }
          this.showProducts = true;
          this.isLoadingNewProducts = false;
        })
        .catch((e) => {
          this["alert/newAlert"]({
            type: "error",
            message: e.data.message,
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);
          this.showProducts = true;
          this.isLoadingNewProducts = false;
          this.noConnectedShop = true;
        });
    },
    getCategories() {
      RetailerProducts.getCategories()
        .then((response) => {
          this.categories = response.data;
        })
        .catch((e) => {
          console.log(e);
          this["alert/newAlert"]({
            type: "error",
            message: e.data.message,
          });
          setTimeout(async () => {
            this.showAlertError = false;
            this["alert/clear"]();
          }, 3000);
        });
    },
    getFilters() {
      RetailerProducts.getFilters()
        .then((response) => {
          this.filters = response.data;
          this.popular_categories = [...response.data.categories]
            .filter((c) => c.is_popular)
            .map((c) => c);
          this.generateChips();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    popularCategory(categoryid) {
      var e = {
        sort_by_object: {
          id: categoryid,
        },
      };
      this.SortByObject(e, "category");
    },
    getCategotyLogo(pic) {
      return require("../../../assets/img/category/" + pic + ".png");
    },
    async SortBySellers(event) {
      this.seller = String(event.multiSelectedValue);
      this.Search();
    },

    async SortByObject(event, type) {
      let pag = this.listOfProductsPagination;
      if (type === "category") {
        if (this.selectedCategory === event) {
          pag.category = undefined;
          this.selectedCategory = undefined;
        } else {
          pag.category = event.id;
          this.selectedCategory = event;
        }
      } else if (type === "shipping_time") {
        if (this.selectedShippingTime === event) {
          pag.shipping_time = undefined;
          this.selectedShippingTime = undefined;
        } else {
          pag.shipping_time = event.id;
          this.selectedShippingTime = event;
        }
      } else if (type === "tag") {
        if (this.selectedTag === event) {
          pag.tag = undefined;
          this.selectedTag = undefined;
        } else {
          pag.tag = event.id;
          this.selectedTag = event;
        }
      } else if (type === "country") {
        if (this.selectedCountry === event) {
          pag.country = undefined;
          this.selectedCountry = undefined;
        } else {
          pag.country = event.id;
          this.selectedCountry = event;
        }
      } else if (type === "dont_show_out_of_stock") {
        if (event) {
          this.selecteddont_show_out_of_stock = 1;
          pag.dont_show_out_of_stock = "false";
        } else {
          pag.dont_show_out_of_stock = undefined;
          this.selecteddont_show_out_of_stock = undefined;
        }
      } else if (type === "sort") {
        if (this.selectedSort === event) {
          pag.sort = undefined;
          this.selectedSort = undefined;
        } else {
          pag.sort = event.id;
          this.selectedSort = event;
        }
      } else {
        return;
      }
      this.lastOffset = this.offset = 0;
      this.page = 1;
      this.showProducts = false;
      this.isLoadingNewProducts = true;

      await this["retailer/setListOfProductsPagination"](pag);
      this.generateRoute();
    },
    next(page) {
      const newPag = JSON.parse(JSON.stringify(this.listOfProductsPagination));
      newPag.offset = this.productCardCount * (page - 1);
      this["retailer/setListOfProductsPagination"](newPag);

      this.getProducts(newPag);
    },
    closeBanner() {
      this["retailer/updateShowMainBanner"](false);
    },
    setProductsCount(count) {
      this["retailer/updateProductsCount"](count);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables2.scss";
.retailer-home-list {
  .sort-container {
    width: 120px !important;
    margin-right: 0 !important;
    @include breakpoint(1180px) {
      width: 120px !important;
    }
    .baseSelect__label__input {
      width: 100%;
    }
  }
  .sort-container-mobile {
    display: none;
    @include breakpoint(500px) {
      display: block;
      width: 100% !important;
    }
  }
  .retailer-home-list-modal {
    position: fixed;
    left: 0;
    top: 0;
    bottom: -100%; /* Initially hidden below the screen */
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    transition: visibility 0.3s ease-in-out;
    z-index: 2222222222222;
    .baseSelect {
      .baseSelect__label {
        border-right: 1px solid #d0d5dd;
      }
    }
  }
  .productList__header__listCount {
    font-size: 14px;
  }
  .visibility-hidden {
    visibility: hidden !important;
  }
  .retailer-home-list-modal-tx {
    transform: translateY(0%) !important;
  }
  .modal-content {
    background-color: white;
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(40vh + 100px);
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
  }
  .chips {
    flex-wrap: wrap;
    row-gap: 10px;
  }
  .chips__item {
    align-items: center;
    padding: 5px;
  }
  .chips__item__text,
  .chips__item__icon {
    font-size: 12px;
    color: #202223;
  }
  .Polaris-Filters-ConnectedFilterControl__CenterContainer {
    margin-right: auto;
    width: 100%;
    z-index: 1;
    padding-right: 16px;
    .input_container {
      height: 100%;
    }
    .input_search {
      background: #fff;
      height: 100%;
    }
    @include breakpoint(500px) {
      padding-right: 0;
    }
  }
  .Polaris-TextField {
    height: 42px;
  }
  .baseSelect {
    width: 170px;
    margin-right: 16px;
    @include breakpoint(1180px) {
      width: 100%;
    }

    .baseSelect__label__dropIcon {
      right: 4px;
    }
    .baseSelect__label {
      padding: 12px 14px;
    }
    .baseSelect__label__input {
      font-size: 12px;
    }
    .baseSelect__label__input::placeholder {
      color: #202223;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    :first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      .baseSelect__label {
        border-right: 1px solid #d0d5dd;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .no-result {
    color: green;
    padding-top: 250px;
    padding-bottom: 250px;
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .products-list-wrapper {
    width: 100%;
    padding-top: 20px;
    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    justify-content: space-between;
    @include md {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }
  .productCard {
    width: 100%;
    margin-right: 60px;
    margin-bottom: 30px;
    transition: 0.3s;
  }
  .chipsContainer {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 20px;
    font-size: 1.6rem;
    @include md {
      flex-direction: column-reverse;
      align-items: flex-start;
      row-gap: 10px;
      margin-top: 10px;
    }
    @include breakpoint(500px) {
      margin-top: -30px;
    }
    &__outOfStock {
      margin: 0;
      margin-left: auto;
    }
    &__clearall {
      color: #008f4a;
      font-weight: 600;
      cursor: pointer;
      position: relative;
      margin-right: 20px;
      &::after {
        content: "";
        width: 0%;
        transition: 0.3s;
        position: absolute;
        height: 1px;
        background-color: #008f4a;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
  }
  .fake_select_wrapper {
    width: 257px !important;
  }

  .advance_search {
    display: flex;
    justify-content: start;
    align-items: stretch;
    @include md {
      column-gap: 10px;
    }
    @include breakpoint(500px) {
      margin-top: 24px;
      flex-direction: column;
      row-gap: 14px;
    }
  }

  .categories {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 16px;

    height: 68px;
    background: #f2f4f7;
    border-radius: 5px;
    margin-bottom: 16px;
  }

  .popular_categories_text {
    display: flex;
    height: 24px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $grey-900;
    align-items: center;
    width: 800px;
  }
  .loadMore {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    &__btn {
      align-self: center;
      background-color: transparent;
      border: 2px solid #008f4a;
      border-radius: 7px;
      color: #008f4a;
      padding: 7px 40px;
      font-size: 1.5rem;
      font-weight: 700;
      transition: 0.3s;
      &:hover {
        padding: 7px 45px;
        background: #008f4a2a;
      }
    }
  }

  .all_categories {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding: 12px;
    gap: 6px;
    padding-left: 45px;
    width: 1500px;
  }

  .cate {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 6px;

    height: 44px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $white;
    background: #fff;
    border-radius: 4px;
    flex-basis: fit-content;
  }

  .sortIcon {
    position: absolute;
    width: 15px;
    margin-top: 16px;
    margin-left: 30px;
  }

  .toggle-wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: center !important;
    display: flex !important;

    .toggle-label {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-right: 20px;
      margin-left: 10px;
    }
  }
  .popular_categories {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 8px;
  }

  .flex-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .flex-container > div {
    margin: 10px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
  }
  .base-select-container-mobile {
    display: none !important;
    justify-content: flex-end;
    @include breakpoint(1180px) {
      display: flex !important;
      width: 360px;
    }
    @include md {
      width: 390px;
      justify-content: unset;
    }
    @include breakpoint(500px) {
      width: 120px;
      margin-right: 0 !important;
      .baseSelect {
        display: none;
      }
    }
    .more-filter-btn {
      color: #202223;
      width: 120px;
      @include breakpoint(1180px) {
        width: 48%;
      }
      @include md {
        width: 50%;
      }
    }

    .more-filter-btn {
      @include md {
        font-size: 12px;
      }

      @include breakpoint(500px) {
        width: 120px;
        margin-right: 0 !important;
      }
    }
  }
  .base-select-container {
    @include breakpoint(1180px) {
      display: none !important;
    }
  }
}
</style>
